const members = [
  // {
  //   name: "Nilabha Das",
  //   nickname: "N3rdys",
  //   designation: "Technical Head",
  //   image: "/Team/nilabha_new.jpg",
  //   facebook: null,
  //   mail: "mailto:nilabha.das@learner.manipal.edu",
  //   linkedin: "https://www.linkedin.com/in/nilabhadas314",
  //   github: "https://github.com/N3dys",
  // },
  // {
  //   name: "Deep Gupta",
  //   nickname: "DeeplyDumb",
  //   designation: "Team Manager",
  //   image: "/Team/deep_new.jpg",
  //   facebook: null,
  //   mail: "mailto:deepgupta2508@gmail.com",
  //   linkedin: "https://www.linkedin.com/in/deep-gupta-35b927204/",
  //   github: "https://github.com/deep-25",
  // },
  // {
  //   name: "Aditya Balaji",
  //   nickname: "Aditya Balaji",
  //   designation: "Research & Development Head",
  //   image: "/Team/balaji.jpg",
  //   facebook: null,
  //   mail: "mailto:adityabalaji3@gmail.com",
  //   linkedin: null,
  //   github: null,
  // },
  // {
  //   name: "Aaron Jomy",
  //   nickname: "Aaron Jomy",
  //   designation: "AI & Robotics Head",
  //   image: "/Team/aaron.jpg",
  //   facebook: null,
  //   mail: "mailto:aaronjomyjoseph@gmail.com",
  //   linkedin: "https://www.linkedin.com/in/aaron-jomy-2312a1201",
  //   github: "https://github.com/maximusron",
  // },
  // {
  //   name: "Tushar Upadhyay",
  //   nickname: "Invok3",
  //   designation: "Sensing & Automation Head",
  //   image: "/Team/tushar_new.jpg",
  //   facebook: null,
  //   mail: "mailto:tushar.u110@gmail.com",
  //   linkedin: "https://www.linkedin.com/in/tushar-upadhyay-wiz/",
  //   github: "https://github.com/tushar-wiz",
  // },
  // {
  //   name: "Divyam Shah",
  //   nickname: "Big Daddy D",
  //   designation: "Mechanical Head",
  //   image: "/Team/divyam.jpg",
  //   facebook: null,
  //   mail: "mailto:yamdiv.02@gmail.com",
  //   linkedin: "https://www.linkedin.com/in/divyam-shah-61bb8a219",
  //   github: null,
  // },
  // {
  //   name: "Amogh Rao",
  //   nickname: "kowalski",
  //   designation: "Management Head",
  //   image: "/Team/amogh.jpg",
  //   facebook: null,
  //   mail: "mailto:amogh1515@gmail.com",
  //   linkedin: "https://www.linkedin.com/in/amogh-vivekananda-rao-62721b19b",
  //   github: null,
  // },
  // {
  //   name: "Sai Anish Malla",
  //   nickname: "Spec_he",
  //   designation: "Senior AI Member",
  //   image: "/Team/anish.jpg",
  //   facebook: null,
  //   mail: "mailto:mallasaianish@gmail.com",
  //   linkedin: null,
  //   github: null,
  // },
  // {
  //   name: "Ayush Kumar",
  //   nickname: "Alcazar",
  //   designation: "Senior AI Member",
  //   image: "/Team/ayush_new.jpg",
  //   facebook: null,
  //   mail: "mailto:ayush_7102@yahoo.in",
  //   linkedin: "https://www.linkedin.com/in/ayushk7102/",
  //   github: "https://github.com/ayushk7102",
  // },
  
  // {
  //   name: "Neetigya Poddar",
  //   nickname: "N3rdys",
  //   designation: "Senior AI Member",
  //   image: "/Team/neetigya_new.jpg",
  //   facebook: null,
  //   mail: "mailto:neetigyapoddar1@gmail.com",
  //   linkedin: "https://www.linkedin.com/in/neetigya-poddar-a022811b6/",
  //   github: "https://github.com/NeetigyaPod",
  // },
  // {
  //   name: "Aditya Choubey",
  //   nickname: "Dulo Peepo",
  //   designation: "Senior Sensing & Automation Member",
  //   image: "/Team/choubey_new.jpg",
  //   facebook: null,
  //   mail: "mailto:adityachoubey2512@gmail.com",
  //   linkedin: "https://www.linkedin.com/in/aditya-choubey-20bb9a1b3",
  //   github: "https://github.com/aditya-c2512",
  // },
  // {
  //   name: "Darshan K S",
  //   nickname: "HomeZ",
  //   designation: "Senior Sensing & Automation Member",
  //   image: "/Team/darshan_new.jpg",
  //   facebook: null,
  //   mail: "mailto:darshanks.work@gmail.com",
  //   linkedin: "https://www.linkedin.com/in/darshan-k-s/",
  //   github: "https://github.com/darshan-k-s",
  // },
  
  // {
  //   name: "Y Vishal Rao",
  //   nickname: "Mongoose",
  //   designation: "Senior Sensing & Automation Member",
  //   image: "/Team/vishal_new.jpg",
  //   facebook: null,
  //   mail: "mailto:yellurvishalrao@gmail.com",
  //   linkedin: null,
  //   github: null,
  // },
  // {
  //   name: "Shreyas Bhat",
  //   nickname: "＼(ﾟ ｰﾟ＼)",
  //   designation: "Senior Mechanical Member",
  //   image: "/Team/shreyas.jpg",
  //   facebook: null,
  //   mail: "mailto:shreyasbhat17@gmail.com",
  //   linkedin: null,
  //   github: "https://github.com/Shreyas-gits",
  // },
  // {
  //   name: "Diya Dhabade",
  //   nickname: "Diya Dhabade",
  //   designation: "Senior Management Member",
  //   image: "/Team/diya.jpg",
  //   facebook: null,
  //   mail: "mailto:diyaddhabade@gmail.com",
  //   linkedin: "https://www.linkedin.com/in/diya-dhabade-7267b6210/",
  //   github: "https://github.com/DiyaDhabade",
  // },
  
  {
    name: "Aman Pandey",
    nickname: "Salamander",
    designation: "Technical Head",
    image: "/Team/aman.jpg",
    facebook: null,
    mail: "mailto:aman.pandey232003@gmail.com",
    linkedin: "https://www.linkedin.com/in/aman-pandey-4b0a25166",
    github: "https://github.com/aman-pandey23",
  },
  {
    name: "Eshaan Shah",
    nickname: "중국어",
    designation: "Team Manager",
    image: "/Team/eshaan.jpg",
    facebook: null,
    mail: "mailto:eshaan.shah@gmail.com",
    linkedin: "https://www.linkedin.com/in/eshaan-shah-06b256215",
    github: "https://github.com/plus-4",
  },
  {
    name: "Yashas Ranjan",
    nickname: "Yashas",
    designation: "Research & Development Head",
    image: "/Team/yashas.jpg",
    facebook: null,
    mail: "mailto:yashasranjan2013@gmail.com",
    linkedin: null,
    github: "https://github.com/sprrrry",
  },
  {
    name: "Divyam Chandalia",
    nickname: "Chandu",
    designation: "AI Head",
    image: "/Team/divyamAI.jpg",
    facebook: null,
    mail: "mailto:divyamchandalia3@gmail.com",
    linkedin: "https://www.linkedin.com/in/divyam-chandalia-73974927b/",
    github: "https://github.com/DivyamChandalia",
  },
  {
    name: "Anjas Biswas",
    nickname: "Anjazsh",
    designation: "Sensing and Automation Head",
    image: "/Team/anjas.jpg",
    facebook: null,
    mail: "mailto:anjasbiswas@gmail.com",
    linkedin: "https://www.linkedin.com/in/anjas-biswas-a07851227",
    github: "https://github.com/AnjasBiswas",
  },
  {
    name: "Prajwal Govind Bhat",
    nickname: "Brown Panther",
    designation: "Mechanical Head",
    image: "/Team/prajwal.jpg",
    facebook: null,
    mail: "mailto:prajwalgb03@gmail.com",
    linkedin: "https://www.linkedin.com/in/prajwal-govind-86789a247",
    github: null,
  },
  {
    name: "Ananya Choubey",
    nickname: "choubs",
    designation: "Management Head",
    image: "/Team/ananya.jpg",
    facebook: null,
    mail: "mailto:ananya.choubey3@gmail.com",
    linkedin: "https://www.linkedin.com/in/ananya-choubey-1101231b0",
    github: null,
  },
  {
    name: "Khalaq Shah",
    nickname: "Kajra Re",
    designation: "Senior AI Member",
    image: "/Team/khalaq.jpg",
    facebook: null,
    mail: "mailto:deepgupta2508@gmail.com",
    linkedin: "https://www.linkedin.com/in/khalaq-shah",
    github: "https://github.com/Khalaq-Shah",
  },
  
  {
    name: "Nathan Adrian Saldanha",
    nickname: "Nathan",
    designation: "Senior AI Member",
    image: "/Team/nathan.jpg",
    facebook: null,
    mail: "mailto:nathansaldanha02@gmail.com",
    linkedin: "https://www.linkedin.com/in/nathan-adrian-saldanha/",
    github: "https://github.com/HyperToken9",
  },
  {
    name: "Rudra Patel",
    nickname: "Ranguy",
    designation: "Senior AI Member",
    image: "/Team/rudra.jpg",
    facebook: null,
    mail: "mailto:rudrapatel9304@gmail.com",
    linkedin: "https://www.linkedin.com/in/rudra-patel-6222821aa",
    github: "https://github.com/ranguy9304",
  },
  {
    name: "Samik Pujari",
    nickname: "shrexy",
    designation: "Senior AI Member",
    image: "/Team/samik.jpg",
    facebook: null,
    mail: "mailto:samikp149@gmail.com",
    linkedin: null,
    github: null,
  },
  
  
  {
    name: "Yuvraj Bhaia",
    nickname: "Sleepwithyuvz",
    designation: "Senior Sensing and Automation Member",
    image: "/Team/yuvraj.jpg",
    facebook: null,
    mail: "mailto:yuvrajbhaiaaa@gmail.com",
    linkedin: "https://www.linkedin.com/in/yuvraj-bhaia-88911420",
    github: null,
  },
  {
    name: "Chintam Sumanth Reddy",
    nickname: "Chintham",
    designation: "Senior Mechanical Member",
    image: "/Team/sumanth.jpg",
    facebook: null,
    mail: "mailto:chinthamreddy22@gmail.com",
    linkedin: null,
    github: null,
  },
  {
    name: "Mahati Balaji",
    nickname: "mahatea",
    designation: "Senior Mechanical Member",
    image: "/Team/mahati.jpg",
    facebook: null,
    mail: "mailto:mahati.balaji@gmail.com",
    linkedin: null,
    github: null,
  },
  
  {
    name: "Riya Kulkarni",
    nickname: "Rio",
    designation: "Senior Mechanical Member",
    image: "/Team/riya.jpg",
    facebook: null,
    mail: "mailto:riyamkulkarni@gmail.com",
    linkedin: "https://www.linkedin.com/in/riya-kulkarni-718a7a233",
    github: null,
  },
  {
    name: "Shreyas Gali",
    nickname: "Shreyu",
    designation: "Senior Mechanical Member",
    image: "/Team/shreyasJunior.jpg",
    facebook: null,
    mail: "mailto:gshreyas2203@gmail.com",
    linkedin: "https://www.linkedin.com/in/shreyas-gali-5baab9207",
    github: null,
  },
  {
    name: "Yash Sethia",
    nickname: "YASH",
    designation: "Senior Mechanical Member",
    image: "/Team/yash.jpg",
    facebook: null,
    mail: "mailto:yash.sethia@learner.manipal.edu",
    linkedin: "https://www.linkedin.com/in/yash-sethia-320462188",
    github: null,
  },
  // {
  //   name: "Aditya Washikar",
  //   nickname: "Washi",
  //   designation: "Management Member",
  //   image: "/Team/washi.jpg",
  //   facebook: null,
  //   mail: "mailto:adityawashikar@gmail.com",
  //   linkedin: "https://www.linkedin.com/in/aditya-washikar-662789241",
  //   github: null,
  // },
  
  {
    name: "Anshita Jaiswal",
    nickname: "myr",
    designation: "Senior Management Member",
    image: "/Team/anshita.jpg",
    facebook: null,
    mail: "mailto:anshitaj038@gmail.com",
    linkedin: "https://www.linkedin.com/in/anshita-jaiswal-243756238",
    github: null,
  },
  // {
  //   name: "Aryenn S Pratap",
  //   nickname: "Yenboi",
  //   designation: "Management Member",
  //   image: "/Team/aryenn.jpg",
  //   facebook: null,
  //   mail: "mailto:aryenns20@gmail.com",
  //   linkedin: "https://www.linkedin.com/in/aryenn-s-pratap-b40a96238",
  //   github: null,
  // },
  // {
  //   name: "Ishan",
  //   nickname: "Techie",
  //   designation: "Management Member",
  //   image: "/Team/ishan.jpg",
  //   facebook: null,
  //   mail: "mailto:ishanagarwal2401@gmail.com",
  //   linkedin: "http://www.linkedin.com/in/ishanagarwal24",
  //   github: null,
  // },
  {
    name: "Jay Patel",
    nickname: "Patss",
    designation: "Senior Management Member",
    image: "/Team/jay.jpg",
    facebook: null,
    mail: "mailto:jayyypatel24@gmail.com",
    linkedin: "https://www.linkedin.com/in/jay-patel-b59206227",
    github: null,
  },
  {
    name: "Aaryan Takayuki Panigrahi",
    nickname: "TAKAYUKI SIN(TT)",
    designation: "AI Member",
    image: "/Team/aaryantaka.png",
    facebook: null,
    mail: "mailto:aaryan.t.panigrahi@gmail.com",
    linkedin: null,
    github: null,
  },
  {
    name: "Aditi Milind Joshi",
    nickname: "ADU DIDI",
    designation: "AI Member",
    image: "/Team/aditijoshi.png",
    facebook: null,
    mail: "mailto:aditij0205@gmail.com",
    linkedin: null,
    github: null,
  },
  {
    name: "Anirudh Agrawal",
    nickname: "DADDY ANIRUDH",
    designation: "AI Member",
    image: "/Team/anirudhagar.png",
    facebook: null,
    mail: "mailto:anirudhagrawal6778@gmail.com",
    linkedin: null,
    github: null,
  },
  {
    name: "David Jijo",
    nickname: "Belt-man",
    designation: "AI Member",
    image: "/Team/davidjijo.png",
    facebook: null,
    mail: "mailto:thisisdavidjijo@gmail.com",
    linkedin: "https://www.linkedin.com/in/david-jijo-a17a32288/",
    github: "https://github.com/AlliedLens",
  },
  {
    name: "Gaurav Pradeep",
    nickname: "9.81-SPOT",
    designation: "AI Member",
    image: "/Team/Gauravpradeep.png",
    facebook: null,
    mail: "mailto:gauravpradeep2004@gmail.com",
    linkedin: "https://www.linkedin.com/in/gaurav-pradeep-352253255?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app",
    github: null,
  },
  {
    name: "Subham Patra",
    nickname: "SUBHU DARLIN'",
    designation: "AI Member",
    image: "/Team/subhampatra.png",
    facebook: null,
    mail: "mailto:patrasubham2004@gmail.com",
    linkedin: "https://www.linkedin.com/in/subham-patra-94938526b?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app",
    github: "https://github.com/AlphaGotReal",
  },
  {
    name: "Vaishnavi Kulkarni",
    nickname: "ADITI",
    designation: "AI Member",
    image: "/Team/vaishnavi.png",
    facebook: null,
    mail: "mailto:vaishnavik002@gmail.com",
    linkedin: null,
    github: null,
  },
  {
    name: "Anaadhi Mongia",
    nickname: "ANAAGIN",
    designation: "AI Member",
    image: "/Team/anaadhi.png",
    facebook: null,
    mail: "mailto:anaadhimongia9+mns@gmail.com",
    linkedin: "https://www.linkedin.com/in/anaadhi-mongia-5890bb210/",
    github: "https://github.com/anaadhi/",
  },
  {
    name: "Animesh Patil",
    nickname: "GOOS",
    designation: "Sensing and Automation Member",
    image: "/Team/animeshpatil.png",
    facebook: null,
    mail: "mailto:animeshmanikpatil@gmail.com",
    linkedin: "https://www.linkedin.com/in/animesh-patil-248993266?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app",
    github: "https://github.com/animeshpatil",
  },
  {
    name: "Naman Kumar",
    nickname: "Nah man",
    designation: "Sensing and Automation Member",
    image: "/Team/namankumar.png",
    facebook: null,
    mail: "mailto:naman.kmr246@gmail.com",
    linkedin: "https://www.linkedin.com/in/naman-kumar-374b6a2a9/?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app",
    github: "https://github.com/00naman",
  },
  {
    name: "Natasha Gonsalves",
    nickname: "NATZ",
    designation: "Sensing and Automation Member",
    image: "/Team/natashagonsalves.png",
    facebook: null,
    mail: "mailto:tashagonsalves0418@gmail.com",
    linkedin: "https://www.linkedin.com/in/natasha-gonsalves-32647529b/",
    github: null,
  },
  {
    name: "Siddharth Mathkar",
    nickname: "MAT KAR",
    designation: "Sensing and Automation Member",
    image: "/Team/siddarthmatkar.png",
    facebook: null,
    mail: "mailto:siddharth.mathkar7@gmail.com",
    linkedin: "www.linkedin.com/in/siddharth-mathkar-314737246",
    github: null,
  },
  {
    name: "Sparsh Srivastava",
    nickname: "SPARK",
    designation: "Sensing and Automation Member",
    image: "/Team/sparsh.png",
    facebook: null,
    mail: "mailto:sparsh1264@gmail.com",
    linkedin: null,
    github: null,
  },
  {
    name: "Vansh Yadav",
    nickname: "VAN$",
    designation: "Sensing and Automation Member",
    image: "/Team/vanshyadav.png",
    facebook: null,
    mail: "mailto:vanshyadav@live.com",
    linkedin: "https://www.linkedin.com/in/vansh-yadav-a7707121a?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app",
    github: "https://github.com/norsechurros",
  },
  {
    name: "Anurag Raut",
    nickname: "ELAI",
    designation: "Mechanical Member",
    image: "/Team/anurag.png",
    facebook: null,
    mail: "mailto:anuragelai@gmail.com",
    linkedin: null,
    github: null,
  },
  {
    name: "Gautham D Nair",
    nickname: "Got Em",
    designation: "Mechanical Member",
    image: "/Team/gauthamnair.png",
    facebook: null,
    mail: "mailto:gauthamdinilnair@gmail.com",
    linkedin: "https://www.linkedin.com/in/gautham-d-nair-bb230b274/?originalSubdomain=in",
    github: null,
  },
  {
    name: "Jaival Shah",
    nickname: "ORCUS",
    designation: "Mechanical Member",
    image: "/Team/jaival.png",
    facebook: null,
    mail: "mailto:jaivalhshah@gmail.com",
    linkedin: "https://www.linkedin.com/in/jaival-shah-6a0310274?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app",
    github: null,
  },
  {
    name: "Nirbhay Aher",
    nickname: "nOP-AMP",
    designation: "Mechanical Member",
    image: "/Team/nirbhay.png",
    facebook: null,
    mail: "mailto:nirbhayaher@gmail.com",
    linkedin: "https://www.linkedin.com/in/nirbhay-aher-338708156/",
    github: null,
  },
  {
    name: "Zain Hussain",
    nickname: "ZED",
    designation: "Mechanical Member",
    image: "/Team/zain.png",
    facebook: null,
    mail: "mailto:zainh1402@gmail.com",
    linkedin: null,
    github: null,
  },
  {
    name: "Chinmayi Bhat",
    nickname: "CHIPMUNK",
    designation: "Management Member",
    image: "/Team/chinmay.png",
    facebook: null,
    mail: "mailto:chinmayibhat.in@gmail.com",
    linkedin: "https://in.linkedin.com/in/chinmayi-bhat-986a63246",
    github: null,
  },
  {
    name: "Diya Nisar",
    nickname: "DIYANASAUR",
    designation: "Management Member",
    image: "/Team/diyanissar.png",
    facebook: null,
    mail: "mailto:diya.nisar777@gmail.com",
    linkedin: "https://www.linkedin.com/in/diya-nisar-495375207/",
    github: null,
  },
  {
    name: "Eric B John",
    nickname: "eRIC ROLL",
    designation: "Management Member",
    image: "/Team/ericbjohn.png",
    facebook: null,
    mail: "mailto:ericbinujohn@gmail.com",
    linkedin: "https://www.linkedin.com/in/eric-b-john46/",
    github: "https://github.com/Eric-B-J",
  },
  {
    name: "Manjyot Saini",
    nickname: "INSAIN",
    designation: "Management Member",
    image: "/Team/manjyotsaini.png",
    facebook: null,
    mail: "mailto:manjyotsaini1911@gmail.com",
    linkedin: "https://www.linkedin.com/in/manjyot-saini-559a7b271?lipi=urn%3Ali%3Apage%3Ad_flagship3_profile_view_base_contact_details%3BihswaCCJRcGsZS0ecRcysA%3D%3D",
    github: null,
  },
  {
    name: "Nikillan Rajesh",
    nickname: "niKILLan",
    designation: "Management Member",
    image: "/Team/nikillan.png",
    facebook: null,
    mail: "mailto:nikillanrajesh@gmail.com",
    linkedin: "https://www.linkedin.com/in/nikillan-rajesh/",
    github: "https://github.com/Nikillanr",
  }



];

export default members;
