import React, { useState } from "react";
import antauri from "../../assets/Projects/antauri.jpg";
import otto from "../../assets/Projects/otto.jpg";
import seraphim from "../../assets/Projects/seraphim.webp";
import cleo from "../../assets/Projects/cleo.webp";
import eve from "../../assets/Projects/eve.webp";
import solo from "../../assets/Projects/solo.webp";
import adam from "../../assets/Projects/adam.webp";
import walle from "../../assets/Projects/walle.webp";
import nova from "../../assets/Projects/nova.jpeg";
import freyja from "../../assets/Projects/freyja.jpeg";
import { Modal } from "antd";

export default function Projects_new() {
  const [isModal1Visible, setIsModal1Visible] = useState(false);
  const [isModal2Visible, setIsModal2Visible] = useState(false);
  const [isModal3Visible, setIsModal3Visible] = useState(false);
  const [isModal4Visible, setIsModal4Visible] = useState(false);
  const [isModal5Visible, setIsModal5Visible] = useState(false);
  const [isModal6Visible, setIsModal6Visible] = useState(false);
  const [isModal7Visible, setIsModal7Visible]= useState(false);
  const [isModal8Visible, setIsModal8Visible]= useState(false);
  const [isModal9Visible, setIsModal9Visible]= useState(false);
  const [isModal10Visible,setIsModal10Visible]= useState(false);

  const showModal1 = () => {
    setIsModal1Visible(true);
  };

  const showModal2 = () => {
    setIsModal2Visible(true);
  };

  const showModal3 = () => {
    setIsModal3Visible(true);
  };
  
  const showModal4 = () => {
    setIsModal4Visible(true);
  };
  
  const showModal5 = () => {
    setIsModal5Visible(true);
  };
  
  const showModal6 = () => {
    setIsModal6Visible(true);
  };

  const showModal7 = () => {
    setIsModal7Visible(true);
  };

  const showModal8 = () => {
    setIsModal8Visible(true);
  };
  const showModal9 = () => {
    setIsModal9Visible(true);
  };
  const showModal10 = () => {
    setIsModal10Visible(true);
  };
  

  const handleCancel1 = () => {
    setIsModal1Visible(false);
  };

  const handleCancel2 = () => {
    setIsModal2Visible(false);
  };

  const handleCancel3 = () => {
    setIsModal3Visible(false);
  };
  
  const handleCancel4 = () => {
    setIsModal4Visible(false);
  };
  
  const handleCancel5 = () => {
    setIsModal5Visible(false);
  };
  
  const handleCancel6 = () => {
    setIsModal6Visible(false);
  };

  const handleCancel7 = () => {
    setIsModal7Visible(false);
  };

  const handleCancel8 = () => {
    setIsModal8Visible(false);
  };
  const handleCancel9 = () => {
    setIsModal9Visible(false);
  };
  const handleCancel10 = () => {
    setIsModal10Visible(false);
  };
  

  return (
    <div className="projects" id="projects">
      <div className="filler"></div>
      <h2>Projects</h2>
        <div className="header-underline"></div>
      <div className="projects-wrapper">

      <Modal
          footer={null}
          title="Our Projects"
          visible={isModal1Visible}
          onCancel={handleCancel1}
          centered={true}
        >
          <h1>ANTAURI</h1>
          <p>  
          Antauri is our entry in the 21st edition of the Student Unmanned Aerial Systems Competition held in Maryland,USA in the year 2023. Among 72 participating teams, it stood as follows:
          <ul>
            <li>● 12th in the world (Overall)</li>
            <li>● 2nd in mission demonstration </li>
            <li>● 12th in the Flight Readiness Review</li>
            <li>● Waypoint Accuracy of 1 metre</li>
          </ul>
          </p>
          </Modal>

      <Modal
          footer={null}
          title="Our Projects"
          visible={isModal2Visible}
          onCancel={handleCancel2}
          centered={true}
        >
          <h1>OTTO</h1>
          <p>  
          OTTO is our third entry into the International Ground Vehicle Challenge held at Oakland University in Rochester, Michigan, USA, in 2023. It stood as follows:
          <ul>
            <li>● 3rd globally in Auto-Navigation out of 25 teams. (Overall)</li>
            <li>● 1st position out of all Indian teams </li>
            <li>● Making MANAS history, it completed over three-fourths of the challenging course.</li>
          </ul>
          </p>
          </Modal>    

        <Modal
          footer={null}
          title="Our Projects"
          visible={isModal3Visible}
          onCancel={handleCancel3}
          centered={true}
        >
          <h1>SERAPHIM</h1>
          <p>  
           Seraphim was our first ever entry into the 20th Student Unmanned 
           Aerial Systems Competition held at St. Mary’s College, Maryland, USA,
           in 2022. Out of 71 teams from across the world, it ranked as follows:
          <ul>
            <li>● 18th in the World (Overall)</li>
            <li>● 2nd in Flight Readiness Review</li>
            <li>● 10th in Technical Design Report</li>
            <li>● 18th in Mission Demonstration</li>
          </ul>
          </p>
          </Modal>

        <Modal
          footer={null}
          title="Our Projects"
          visible={isModal4Visible}
          onCancel={handleCancel4}
          centered={true}
        >
          <h1>CLEO</h1>
          <p>  
           Cleo is our first attempt at a drone. It was built as a testing 
           benchmark for our autonomous waypoint navigation, path planning and
           coverage planning algorithms. The key features of Cleo are as 
           follows:
           <ul>
            <li>● Capable of Autonomous flight for 20 minutes.</li>
            <li>● Achieves Static Obstacle Avoidance.</li>
            <li>● Maximum Altitude 100 ft</li>
            <li>● Waypoint Accuracy of 1.3 m</li>
          </ul>
          </p>
          </Modal>
      
      <Modal
          footer={null}
          title="Our Projects"
          visible={isModal5Visible}
          onCancel={handleCancel5}
          centered={true}
        >
          <h1>EVE</h1>
          <p>
           Eve, our driverless car, boasts a software stack capable of Level 2 
           autonomy behaviour such as real-time navigation and mapping, as 
           well as lane following and switching.

           Project MANAS is the youngest and the only fully undergraduate team
           to qualify for the Mahindra Rise Prize challenge where we stood in 
           the top 13 finalists out of 300 teams.
          </p>
        </Modal>
      
      <Modal
          footer={null}
          title="Our Projects"
          visible={isModal6Visible}
          onCancel={handleCancel6}
          centered={true}
        >
          <h1>SOLO</h1>
          <p>  
           Solo was developed as a successor to Adam, taking part in the 
           International Ground Vehicle Challenge in 2019, and received various
           accolades. Among 46 teams, Solo stood as follows:

           <ul>
            <li>● Grand Prize Winner (Overall).</li>
            <li>● 1st in the Interoperability Challenge</li>
            <li>● 2nd in Overall Robot Design</li>
            <li>● 6th in Cyber Security</li>
            <li>● 12th in Auto Navigation</li>
          </ul>       
          </p>
          </Modal>
    
      <Modal
          footer={null}
          title="Our Projects"
          visible={isModal7Visible}
          onCancel={handleCancel7}
          centered={true}
        >
          <h1>ADAM</h1>
          <p>  
            Adam is an autonomous ground vehicle, which was our first entry into
            the International Ground Vehicle Challenge, held at Oakland University,
            Michigan, USA, in 2018. Among 26 teams, Adam achieved the following
            results:
            <ul>
            <li>● 9th in the World (Overall)</li>
            <li>● 2nd in India (Overall)</li>
            <li>● 2nd in the Interoperability Challenge</li>
          </ul>                   
          </p>
        </Modal>

      <Modal
          footer={null}
          title="Our Projects"
          visible={isModal8Visible}
          onCancel={handleCancel8}
          centered={true}
        >
          <h1>WALL-E</h1>
          <p>
            Our first stride into the autonomous robots started with Wall-E. It
            was built as a proof of concept. Wall-E is where the journey and
            dream began.
          </p>
        </Modal>
      <Modal
          footer={null}
          title="Our Projects"
          visible={isModal9Visible}
          onCancel={handleCancel9}
          centered={true}
        >
          <h1>NOVA</h1>
          <p>  
            Nova is an autonomous ground vehicle, which was our entry into
            the Intelligent Ground Vehicle Challenge, held at Oakland University,
            Michigan, USA, in 2024. Nova achieved the following
            results:
          <ul>
            <li>● 1st Place in the Design Challenge </li>
            <li>● 6th Place in the Auto-Navigation Challenge</li>
          </ul>          
          </p>
          </Modal>
      <Modal
          footer={null}
          title="Our Projects"
          visible={isModal10Visible}
          onCancel={handleCancel10}
          centered={true}
        >
          <h1>Freyjä</h1>

          <p>  
          Freyjä is our latest entry in the 22nd annual Student Unmanned Aerial Systems (SUAS) Competition, held in Maryland, USA in the year 2024. Freyjä stood as follows:
          <ul>
            <li>● 10th overall out of 72 international Teams</li>
            <li>● 4th in Mission Demonstration </li>
            <li>● 8th in Flight Readiness Review</li>
          </ul>
          </p>
          </Modal>

    
        <div class="module-border-wrap">
        <div
            onClick={showModal10}
            class="module"
            style={{ backgroundImage: `url(${freyja})` }}
          >
            <div class="module-overlay">
              <p>FREYJÄ</p>
            </div>
          </div>
        </div>
        <div class="module-border-wrap">
          <div
            onClick={showModal9}
            class="module"
            style={{ backgroundImage: `url(${nova})` }}
          >
            <div class="module-overlay">
              <p>NOVA</p>
            </div>
          </div>
        </div>
        
        <div class="module-border-wrap">
          <div
            onClick={showModal1}
            class="module"
            style={{ backgroundImage: `url(${antauri})` }}
          >
            <div class="module-overlay">
              <p>ANTAURI</p>
            </div>
          </div>
        </div>

        <div class="module-border-wrap">
          <div
            onClick={showModal2}
            class="module"
            style={{ backgroundImage: `url(${otto})` }}
          >
            <div class="module-overlay">
              <p>OTTO</p>
            </div>
          </div>
        </div>

        <div class="module-border-wrap">
          <div
            onClick={showModal3}
            class="module"
            style={{ backgroundImage: `url(${seraphim})` }}
          >
            <div class="module-overlay">
              <p>SERAPHIM</p>
            </div>
          </div>
        </div>
        
          <div class="module-border-wrap">
           <div
              onClick={showModal4}
              class="module"
              style={{ backgroundImage: `url(${cleo})` }}
            >
            <div class="module-overlay">
              <p>CLEO</p>
            </div>
          </div>
        </div>
        
        <div class="module-border-wrap">
          <div
            onClick={showModal5}
            class="module"
            style={{ backgroundImage: `url(${eve})` }}
          >
            <div class="module-overlay">
              <p>EVE</p>
            </div>
          </div>
        </div>
        
        
        <div class="module-border-wrap">
          <div
            onClick={showModal6}
            class="module"
            style={{ backgroundImage: `url(${solo})` }}
          >
            <div class="module-overlay">
              <p>SOLO</p>
            </div>
          </div>
        </div>
        
    
        <div class="module-border-wrap">
          <div
            onClick={showModal7}
            class="module"
            style={{ backgroundImage: `url(${adam})` }}
          >
            <div class="module-overlay">
              <p>ADAM</p>
            </div>
          </div>
        </div>

        <div class="module-border-wrap">
          <div
            onClick={showModal8}
            class="module"
            style={{ backgroundImage: `url(${walle})` }}
          >
            <div class="module-overlay">
              <p>WALL-E</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
