const alums = [
  {
    name: "Kumar Ranjan",
    nickname: "Team Leader (March, 2014 - July, 2015)",
    designation: "Co-Founder",
    image: "/Alumni/kumar-ranjan.jpg",
    facebook: "https://www.facebook.com/thelostmechanic",
    mail: null,
    linkedin: null,
    github: null,
    year: 2015,
  },

  {
    name: "Roshan Prakash",
    nickname: "Team Leader (July, 2015 - April, 2016)",
    designation: "Co-Founder",
    image: "/Alumni/roshan-prakash.jpg",
    facebook: null,
    mail: null,
    linkedin: "https://in.linkedin.com/in/roshan-prakash-042ab9b1",
    github: null,
    year: 2016,
  },

  {
    name: "Shubham Verma",
    nickname: null,
    designation: "AI Head (March, 2014 - December, 2015)",
    image: "/Alumni/subham-verma.jpg",
    facebook: null,
    mail: null,
    linkedin: "https://in.linkedin.com/in/shubhamvrm",
    github: null,
    year: 2015,
  },
  {
    name: "Tushar Sinha",
    nickname: null,
    designation: "Team Managar (July, 2015 - April, 2016)",
    image: "/Alumni/tushar-sinha.jpg",
    facebook: null,
    mail: null,
    linkedin: "https://www.linkedin.com/in/tushar-sinha-7ba08258",
    github: null,
    year: 2016,
  },

  {
    name: "Akash Deep",
    nickname: null,
    designation: "SnA Division Head (July, 2015 - April, 2016)",
    image: "/Alumni/akash-deep.jpg",
    facebook: null,
    mail: null,
    linkedin: "https://www.linkedin.com/in/akash-deep-tyagi-394ab7106/",
    github: null,
    year: 2016,
  },
  {
    name: "Harsh Barde",
    nickname: "HUB",
    designation: "Technical Head (March 2020- May 2021)",
    image: "https://i.imgur.com/wReSEs8.png",
    facebook: null,
    mail: "mailto:harshbarde51@gmail.com",
    linkedin: "https://www.linkedin.com/in/harsh-barde-646304169",
    github: null,
    year: 2021,
  },
  {
    name: "Ritwik Agarwal",
    nickname: "Ritwik Agarwal",
    designation: "Team Manager (March 2020- May 2021)",
    image: "https://i.imgur.com/ezzTmTm.png",
    facebook: null,
    mail: "mailto:ritwikagarwal44@gmail.com",
    linkedin: "https://www.linkedin.com/in/ritwik-agarwal",
    github: "https://github.com/ritwik25",
    year: 2021,
  },
  {
    name: "Dhruv Joshi",
    nickname: "helium_balloons",
    designation: "AI Head (March 2020- May 2021)",
    image: "https://i.imgur.com/FKT7lSK.png",
    facebook: null,
    mail: "mailto:dhruvjoshi1007@gmail.com",
    linkedin: "https://www.linkedin.com/in/dhruv-joshi-b65a6b16a",
    github: "https://github.com/dhruvjoshi1007",
    year: 2021,
  },
  {
    name: "Arpit Chauhan",
    nickname: "Arpit Chauhan",
    designation: "S&A Head (March 2020- May 2021)",
    image: "https://i.imgur.com/WYJwAGh.png",
    facebook: "https://www.facebook.com/profile.php?id=100026317991091",
    mail: "mailto:carpit680@gmail.com",
    linkedin: "https://www.linkedin.com/in/arpit-chauhan-bb84bb145",
    github: "https://github.com/carpit680",
    year: 2021,
  },
  {
    name: "Rohit Natu",
    nickname: "Rohit Natu",
    designation: "Mech Head (March 2020- May 2021)",
    image: "https://i.imgur.com/e79KSbS.png",
    facebook: null,
    mail: "mailto:rohit.grandson@gmail.com",
    linkedin: "https://www.linkedin.com/in/rohit-natu-42b005180",
    github: "https://www.github.com/75072107",
    year: 2021,
  },
  {
    name: "Dhaval Machhar",
    nickname: "kRma",
    designation: "Management Head (March 2020- May 2021)",
    image: "https://i.imgur.com/DvqDaz0.png",
    facebook: null,
    mail: "mailto:dhavalmchhr@gmail.com",
    linkedin: "https://www.linkedin.com/in/dhaval-machhar-ba03191a0",
    github: "https://github.com/dhaval-machhar",
    year: 2021,
  },
  {
    name: "Anish Biswas",
    nickname: "trickarcher",
    designation: "AI Planning Head (March 2020- May 2021)",
    image: "https://i.imgur.com/ncBT26s.png",
    facebook: "https://www.facebook.com/theAnishBiswas",
    mail: "mailto:anishbiswas271@gmail.com",
    linkedin: "https://www.linkedin.com/in/anish-biswas",
    github: "https://github.com/trickarcher",
    year: 2021,
  },
  {
    name: "Akhil Bonagiri",
    nickname: "Akhil Bonagiri",
    designation: "Automation Head (March 2020- May 2021)",
    image: "https://i.imgur.com/4O52Y9p.png",
    facebook: null,
    mail: "mailto:bonagiri_akhil@yahoo.com",
    linkedin: "https://www.linkedin.com/in/akhil-bonagiri-2569b9181",
    github: null,
    year: 2021,
  },
  {
    name: "Gokul P",
    nickname: "Gokul P",
    designation: "Sensing Head (March 2020- May 2021)",
    image: "https://i.imgur.com/4iTw3VF.png",
    facebook: null,
    mail: "mailo:gokulgns@mailcom",
    linkedin: "htps://wwwlinkedin.com/in/gokul-p-38b22b154",
    github: "https//github.com/gokulp01",
    year: 2021,
  },

  {
    name: "Sarthake Choudhary",
    nickname: null,
    designation: "Team Leader (March, 2017 - March, 2018)",
    image: "/Team/sarthanke-choudhary.jpg",
    facebook: null,
    mail: "sarthak.choudhary24@gmail.com",
    linkedin: null,
    github: null,
    year: 2018,
  },
  {
    name: "Raj Nunes",
    nickname: null,
    designation: "Technical Head (March, 2017 - March, 2018",
    image: "/Team/raj-nunes.jpg",
    facebook: null,
    mail: "rrn.raj@gmail.com",
    linkedin: null,
    github: null,
    year: 2018,
  },
  {
    name: "Tanmay Agarwal",
    nickname: null,
    designation: "AI Head (March, 2017 - March, 2018",
    image: "/Team/tanmay-agrawal.jpg",
    facebook: null,
    mail: "tanmayagr98@gmail.com",
    linkedin: null,
    github: null,
    year: 2018,
  },
  {
    name: "Kartikey Agarwal",
    nickname: null,
    designation: "Sensing and Automation Head (March, 2017 - March, 2018",
    image: "/Team/kartikey-agrawal.jpg",
    facebook: null,
    mail: "kartikey04.05@gmail.com",
    linkedin: null,
    github: null,
    year: 2018,
  },
  {
    name: "Pranav Jain",
    nickname: null,
    designation: "Mechanical Head (March, 2017 - March, 2018",
    image: "/Team/pranav-jain.jpg",
    facebook: null,
    mail: "pranavjain977@gmail.com",
    linkedin: null,
    github: null,
    year: 2018,
  },
  {
    name: "Haran Ravindran",
    nickname: null,
    designation: "AI Member (March, 2017 - March, 2018)",
    image: "/Team/haran.jpg",
    facebook: null,
    mail: null,
    linkedin: "https://www.linkedin.com/in/haran-ravindran-7220a3125/",
    github: null,
    year: 2018,
  },
  {
    name: " Abhishek Yadav",
    nickname: null,
    designation: "AI Member (March, 2017 - March, 2018)",
    image: "/Team/abhishek-y.jpg",
    facebook: null,
    mail: null,
    linkedin: "https://www.linkedin.com/in/abhishek-yadav-seb/",
    github: null,
    year: 2018,
  },

  {
    name: "Ashish Mehta",
    nickname: null,
    designation: "Team Leader (April, 2016 - March, 2017)",
    image: "/Alumni/ashish-mehta.jpg",
    facebook: null,
    mail: "ashishm20795@gmail.com",
    linkedin: null,
    github: null,
    year: 2017,
  },
  {
    name: "Reuben John",
    nickname: null,
    designation: "Technical Head (April, 2016 - March, 2017)",
    image: "/Alumni/reuben-john.jpg",
    facebook: null,
    mail: "reubenvjohn@gmail.com",
    linkedin: null,
    github: null,
    year: 2017,
  },
  {
    name: "Surya Phatak",
    nickname: null,
    designation: "Team Manager (April, 2016 - March, 2017)",
    image: "/Alumni/surya-phatak.jpg",
    facebook: null,
    mail: null,
    linkedin: "https://www.linkedin.com/in/surya-phatak-a35b80118/",
    github: null,
    year: 2017,
  },

  {
    name: "Kartik Seshadri Chari",
    nickname: null,
    designation: "SnA Head (April, 2016 - March, 2017)",
    image: "/Alumni/karthik-chari.jpg",
    facebook: null,
    mail: null,
    linkedin: "https://www.linkedin.com/in/kartikchari/",
    github: null,
    year: 2017,
  },

  {
    name: "Tanay Verma",
    nickname: null,
    designation: "Mechanical Head (April, 2016 - March, 2017)",
    image: "/Alumni/tanay-verma.jpg",
    facebook: null,
    mail: null,
    linkedin: "https://www.linkedin.com/in/tanayvarma123/",
    github: null,
    year: 2017,
  },
  {
    name: "Shivani Modi",
    nickname: null,
    designation: "AI Head (April, 2016 - March, 2017)",
    image: "/Team/shivani-modi.jpg",
    facebook: null,
    mail: "sm5060@columbia.edu",
    linkedin: null,
    github: null,
    year: 2017,
  },

  {
    name: "Yash Bansod",
    nickname: null,
    designation: "Automation Head (April, 2016 - March, 2017)",
    image: "/Alumni/yash-bansod.jpg",
    facebook: null,
    mail: null,
    linkedin: "https://www.linkedin.com/in/yashbansod/",
    github: null,
    year: 2017,
  },

  {
    name: "Phanikrishna Kalluri",
    nickname: null,
    designation: "Mechanics Head (April, 2016 - March, 2017)",
    image: "/Alumni/phanikrishna-kalluri.jpg",
    facebook: null,
    mail: null,
    linkedin: "https://www.linkedin.com/in/phanikrishna-kalluri-6b5b45118/",
    github: null,
    year: 2017,
  },

  {
    name: "Kaushik Nath",
    nickname: null,
    designation: "Perception Head (Sep, 2016 - Aug, 2017)",
    image: "/Alumni/kaushik-nath.jpg",
    facebook: null,
    mail: null,
    linkedin: "https://www.linkedin.com/in/kaushikmit/",
    github: null,
    year: 2017,
  },

  {
    name: "Debang Sharma",
    nickname: null,
    designation: "SnA Member (April, 2016 - March, 2017)",
    image: "/Alumni/debang-sharma.jpg",
    facebook: null,
    mail: null,
    linkedin: "https://www.linkedin.com/in/dbngshrma/",
    github: null,
    year: 2017,
  },
  
  {
    name: "Fateh Singh",
    nickname: null,
    designation: "AI Member (April, 2016 - March, 2017)",
    image: "/Alumni/fateh-singh.jpg",
    facebook: null,
    mail: "fateh288@gmail.com",
    linkedin: null,
    github: null,
    year: 2017,
  },
  

  {
    name: "Dheeraj R. Reddy",
    nickname: "Squadrick",
    designation: "Team Leader (March, 2018 - March, 2019)",
    image: "/Alumni/dheeraj.png",
    facebook: null,
    mail: "mailto:dheeraj98reddy@gmail.com",
    linkedin: "https://www.linkedin.com/in/dheeraj-rajaram-reddy/",
    github: "https://squadrick.dev",
    year: 2019,
  },
  {
    name: "Mukesh Kumar",
    nickname: "Mukesh Kumar",
    designation: "Tech Head (March, 2018 - March, 2019)",
    image: "/Alumni/mukesh.jpg",
    facebook: "https://www.facebook.com/dheeraj.r.reddy",
    mail: "mailto:mukeshkumar199739@gmail.com",
    linkedin: null,
    github: null,
    year: 2019,
  },
  {
    name: "Sanjeev Nayak",
    nickname: "Sanjeev Nayak",
    designation: "Team Manager (March, 2018 - March, 2019)",
    image: "/Alumni/sanjeev.png",
    facebook: "https://www.facebook.com/sanjeev.nayak.39545",
    mail: "mailto:sanjeevnayak9@gmail.com",
    linkedin: "https://www.linkedin.com/mwlite/me",
    github: null,
    year: 2019,
  },
  {
    name: "Sarthak Mittal",
    nickname: "naivehobo",
    designation: "AI Head (March, 2018 - March, 2019)",
    image: "/Alumni/10.gif",
    facebook: "https://www.facebook.com/sarthak.mittal2",
    mail: "mailto:sarthakmittal2608@gmail.com",
    linkedin: null,
    github: "https://github.com/naiveHobo",
    year: 2019,
  },
  {
    name: "Pranjal Sinha",
    nickname: "Pranjal Sinha",
    designation: "SnA Head (March, 2018 - March, 2019)",
    image: "/Alumni/32.jpeg",
    facebook: null,
    mail: null,
    linkedin: "https://www.linkedin.com/in/pranjal-sinha-457114138/",
    github: null,
    year: 2019,
  },
  {
    name: "Ansh Verma",
    nickname: "Ansh Verma",
    designation: "Mechanical Head (March, 2018 - March, 2019)",
    image: "/Alumni/ansh.png",
    facebook: "https://www.facebook.com/vermaansh135",
    mail: null,
    linkedin: "https://www.linkedin.com/in/ansh-verma-b5607713a/",
    github: null,
    year: 2019,
  },
  {
    name: "Apratim Mukhrjee",
    nickname: "Apratim Mukhrjee",
    designation: "AI Perception Head (March, 2018 - March, 2019)",
    image: "/Alumni/18.jpeg",
    facebook: "https://www.facebook.com/opletts",
    mail: "mailto:apratim101@gmail.com",
    linkedin: "https://www.linkedin.com/in/apratim-mukherjee/",
    github: "https://opletts.github.io",
    year: 2019,
  },
  {
    name: "Shreyas Sharma",
    nickname: "Shreyas Sharma",
    designation: "AI Planning Head (March, 2018 - March, 2019)",
    image: "/Alumni/44.jpeg",
    facebook: null,
    mail: "mailto:shreyas.rox101@gmail.com",
    linkedin: "https://www.linkedin.com/in/shreyas-sharma-443680130/",
    github: "https://github.com/shreyashub",
    year: 2019,
  },
  {
    name: "Vishnu Menon",
    nickname: "Vishnu Menon",
    designation: "Mech Mechanics Head (March, 2018 - March, 2019)",
    image: "/Alumni/35.jpeg",
    facebook: "https://m.facebook.com/vishnu.menon.946",
    mail: "mailto:vishnumenon9898@gmail.com",
    linkedin: null,
    github: null,
    year: 2019,
  },
  {
    name: "Damodar Nayak",
    nickname: "Damodar Nayak",
    designation: "Mech Senior Member (March, 2018 - March, 2019)",
    image: "/Alumni/21.jpeg",
    facebook: null,
    mail: null,
    linkedin: null,
    github: null,
    year: 2019,
  },
  {
    name: "Johir Suresh",
    nickname: "Johir Suresh",
    designation: "Mech Senior Member (March, 2018 - March, 2019)",
    image: "/Alumni/26.jpeg",
    facebook: "https://www.facebook.com/johir.suresh",
    mail: "mailto:johirsuresh1998@gmail.com",
    linkedin: "https://www.linkedin.com/in/johir-suresh-59252a129/",
    github: null,
    year: 2019,
  },
  {
    name: "Tanuj Agarwal",
    nickname: "Tanuj Agarwal",
    designation: "Mech Mechanics Member (March, 2018 - March, 2019)",
    image: "/Alumni/tanuj.png",
    facebook: null,
    mail: "mailto:tanujma99@gmail.com",
    linkedin: "https://www.linkedin.com/in/tanuj-agrawal-88a149139",
    github: null,
    year: 2019,
  },
  {
    name: "Shrijit Singh",
    title: "Tech Head (March, 2019 - March, 2020)",
    designation: "Tech Head (March, 2019 - March, 2020)",
    image: "/Alumni/12.jpeg",
    facebook: "https://www.facebook.com/shrijitsingh99",
    mail: "mailto:shrijitsingh99@gmail.com",
    linkedin: "https://www.linkedin.com/in/shrijit-singh-81b86a145/",
    github: "https://github.com/shrijitsingh99",
    year: 2020,
  },
  {
    name: "Tanaya Mandke",
    title: "Team Manager (March, 2019 - March, 2020)",
    designation: "Team Manager (March, 2019 - March, 2020)",
    image: "/Alumni/26.png",
    facebook: "https://www.facebook.com/tana.mandke",
    mail: "mailto:Tanaya.mandke9299@gmail.com",
    linkedin: "http://linkedin.com/in/tanaya-mandke-885b24165",
    github: null,
    year: 2020,
  },
  {
    name: "Shivesh Khaitan",
    title: "Head of Robotics (March, 2019 - March, 2020)",
    designation: "Head of Robotics (March, 2019 - March, 2020)",
    image: "/Alumni/45.jpeg",
    facebook: "https://www.facebook.com/shivesh.khaitan",
    mail: "mailto:shiveshkhaitan@gmail.com",
    linkedin: "https://www.linkedin.com/in/shivesh-khaitan-b48225159/",
    github: "https://github.com/shiveshkhaitan",
    year: 2020,
  },
  {
    name: "Rishab Agarwal",
    title: "S&A Head (March, 2019 - March, 2020)",
    designation: "S&A Head (March, 2019 - March, 2020)",
    image: "/Alumni/38.png",
    facebook: "https://www.facebook.com/Agarwal.Rishab.99",
    mail: "mailto:rishabgrwl121@gmail.com",
    linkedin: "https://www.linkedin.com/in/rishab-agarwal-8a959216a",
    github: null,
    year: 2020,
  },
  {
    name: "Arya Karani",
    title: "Mechanical Head (March, 2019 - March, 2020)",
    designation: "Mechanical Head (March, 2019 - March, 2020)",
    image: "/Alumni/20.jpeg",
    facebook: null,
    mail: "mailto:karani.arya@gmail.com",
    linkedin: "https://www.linkedin.com/in/surampalli-rakesh-450213160/",
    github: null,
    year: 2020,
  },
  {
    name: "Sarathkrishnan Ramesh",
    title: "AI Planning Head (March, 2019 - March, 2020)",
    designation: "AI Planning Head (March, 2019 - March, 2020)",
    image: "/Alumni/13.jpeg",
    facebook: "https://www.facebook.com/sarathkrishnan.ramesh",
    mail: "mailto:sarathkrishnan99@gmail.com",
    linkedin: "https://www.linkedin.com/in/sarathkrishnan-ramesh-0a75a315b",
    github: "https://www.github.com/Sarath18",
    year: 2020,
  },
  {
    name: "Shivanshu Agarwal",
    title: "Sensing Head (March, 2019 - March, 2020)",
    designation: "Sensing Head (March, 2019 - March, 2020)",
    image: "/Alumni/39.png",
    facebook: "https://w ww.facebook.com/shivanshu.agarwal.965",
    mail: "mailto:agarwalshivanshu00@gmail.com",
    linkedin: "https://www.linkedin.com/in/shivansu-agarwal-53b625136",
    github: "https://github.com/shic15",
    year: 2020,
  },
  {
    name: "Dheeraj Mohan",
    title: "AI Perception Head (March, 2019 - March, 2020)",
    designation: "AI Perception Head (March, 2019 - March, 2020)",
    image: "/Alumni/24.jpeg",
    facebook: "https://facebook.com/dheeraj.McDhee",
    mail: "mailto:dheeraj.98@outlook.com",
    linkedin: "https://www.linkedin.com/in/dheeraj-mcdhee",
    github: "https://www.github.com/dheeraj-coding",
    year: 2020,
  },
  {
    name: "Dasarath S",
    title: "Automation Head (March, 2019 - March, 2020)",
    designation: "Automation Head (March, 2019 - March, 2020)",
    image: "/Alumni/23.jpeg",
    facebook: "https://facebook.com/dasarath.selvakumar",
    mail: "mailto:dasarathselvakumar@gmail.com",
    linkedin: "https://www.linkedin.com/in/dasarath-s-66525b157",
    github: null,
    year: 2020,
  },
  {
    name: "Siddarth Venkatraman",
    title: "AI Planning Member (Feb, 2018 - March, 2020)",
    designation: "AI Planning Member (Feb, 2018 - March, 2020)",
    image: "/Alumni/43.jpeg",
    facebook: null,
    mail: "mailto:siddarthegreat@gmail.com",
    linkedin: "https://www.linkedin.com/in/siddarth-venkatraman-59b863157/",
    github: "https://github.com/HyperPotatoNeo",
    year: 2020,
  },
  {
    name: "Ansel Dias",
    title: "S&A Sensing Member (Feb, 2018 - March, 2020)",
    designation: "S&A Sensing Member (Feb, 2018 - March, 2020)",
    image: "/Alumni/36.png",
    facebook:
      "https://www.facebook.com/profile.php?id=100010693602083&ref=br_rs",
    mail: "mailto:thegreatanseldias@gmail.com",
    linkedin: "https://www.linkedin.com/in/ansel-dias-a7993215b/",
    github: null,
    year: 2020,
  },
  {
    name: "Raj Tulluri",
    title: "S&A Automation Member (Jan, 2019 - March, 2020)",
    designation: "S&A Automation Member (Jan, 2019 - March, 2020)",
    image: "/Alumni/43.png",
    facebook: null,
    mail: "mailto:mail@rajabbastulluri.com",
    linkedin: "https://www.linkedin.com/in/raj-tulluri-165977145/",
    github: null,
    year: 2020,
  },
  {
    name: "Chaitanya S",
    title: "AI Perception Member (Feb, 2018 - March, 2020)",
    designation: "AI Perception Member (Feb, 2018 - March, 2020)",
    image: "/Alumni/8.png",
    facebook: "https://www.facebook.com/chaitanya.shyamaraj.37",
    mail: "mailto:chaitanya2.1999@gmail.com",
    linkedin: "https://www.linkedin.com/in/chaitanya21999",
    github: "https://github.com/Chaitanya-git",
    year: 2020,
  },
  {
    name: "Anurag Borkar",
    title: "AI Perception Member (Feb, 2019 - March, 2020)",
    designation: "AI Perception Member (Feb, 2019 - March, 2020)",
    image: "/Alumni/49.jpeg",
    facebook: "https://www.facebook.com/anurag.borkar.376",
    mail: "mailto:anuragvborkar@gmail.com",
    linkedin: "https://www.linkedin.com/in/anurag-borkar-9b3645166",
    github: "https://github.com/anuragvborkar",
    year: 2020,
  },
  {
    name: "Parthesh Savla",
    title: "Mechanics Member (Feb, 2018 - March, 2020)",
    designation: "Mechanics Member (Feb, 2018 - March, 2020)",
    image: "/Alumni/25.png",
    facebook: "https://www.facebook.com/prince.savla.9",
    mail: "mailto:parthesh.savla@gmail.com",
    linkedin: "https://www.linkedin.com/in/parthesh-savla327322163",
    github: null,
    year: 2020,
  },
  {
    name: "Adheesh H M",
    title: "AI Perception Member (Feb, 2018 - March, 2020)",
    designation: "AI Perception Member (Feb, 2018 - March, 2020)",
    image: "/Alumni/51.jpeg",
    facebook: null,
    mail: "mailto:adheesh1010@gmail.com",
    linkedin: "https://www.linkedin.com/in/adheesh-h-m-14283912b",
    github: null,
    year: 2020,
  },
  {
    name: "Aditya Veerabahu",
    nickname: "Aditya Veerabahu",
    designation: "Admin & Finance Head (2019 - 2020)",
    image: "/Alumni/13.png",
    facebook: "https://www.facebook.com/profile.php?id=100008935382776",
    mail: "mailto:adityaveerabahu@gmail.com",
    linkedin: "https://www.linkedin.com/in/aditya-veerabahu",
    github: "https://github.com/adityaveera17",
    year: 2020,
  },
  {
    name: "Abhineet Choudhary",
    nickname: "Abhineet Choudhary",
    designation: "AI Perception Head (March 2020- May 2021)",
    image: "/Alumni/3.png",
    facebook: null,
    mail: "mailto:abhi.chou4@gmail.com",
    linkedin: "https://www.linkedin.com/in/abhineet-c-344967bb/",
    github: "https://github.com/abhichou4",
    year: 2021,
  },
  {
    name: "Aniket Bhawe",
    nickname: "Aniket Bhawe",
    designation: "Mechanics Head (March 2020- May 2021)",
    image: "/Alumni/22.png",
    facebook: "https://www.facebook.com/aniket.bhawe.3",
    mail: "mailto:kiwiani99@gmail.com",
    linkedin: "http://linkedin.com/in/aniket-bhawe",
    github: null,
    year: 2021,
  },
  {
    name: "Debayan Deb",
    nickname: "Devayern",
    designation: "Design Head (March 2020- May 2021)",
    image: "/Alumni/28.png",
    facebook: "https://www.facebook.com/profile.php?id=100008758214121",
    mail: "mailto:devion008@gmail.com",
    linkedin: "https://www.linkedin.com/in/debayan-deb-10311617b",
    github: null,
    year: 2021,
  },
  {
    name: "Baidyanath Kundu",
    nickname: "sudo-panda",
    designation: "Senior AI Member (March 2020- May 2021)",
    image: "/Alumni/1.png",
    facebook: "https://www.facebook.com/Baidya99",
    mail: "mailto:kundubaidya99@gmail.com",
    linkedin: "https://www.linkedin.com/in/baidyanath-kundu-025b41142",
    github: "https://github.com/sudo-panda",
    year: 2021,
  },
  {
    name: "Sahil Khose",
    nickname: "Sahil Khose",
    designation: "Senior AI Member (March 2020- May 2021)",
    image: "/Alumni/2.png",
    facebook: "https://www.facebook.com/sahil.khose.3",
    mail: "mailto:sahilkhose18@gmail.com",
    linkedin: "https://www.linkedin.com/in/sahil-khose-20a621166/",
    github: "https://github.com/sahilkhose",
    year: 2021,
  },
  {
    name: "Aneesh Chawla",
    nickname: "aneesh404",
    designation: "Senior AI Member (March 2020- May 2021)",
    image: "/Alumni/6.png",
    facebook: null,
    mail: "mailto:aneeshchawla404@gmail.com",
    linkedin: "https://www.linkedin.com/in/aneesh-chawla-986165171",
    github: "https://github.com/aneesh404",
    year: 2021,
  },
  {
    name: "Garima Singh",
    nickname: "grimmyshini",
    designation: "Senior AI Member (March 2020- May 2021)",
    image: "/Alumni/7.png",
    facebook: null,
    mail: "mailto:garimasingh0028@gmail.com",
    linkedin: "https://www.linkedin.com/in/garima-singh-392856189/",
    github: "https://github.com/grimmmyshini",
    year: 2021,
  },
  {
    name: "Kishore K",
    nickname: "Kishore K",
    designation: "Senior S&A Member (March 2020- May 2021)",
    image: "/Alumni/32.png",
    facebook: null,
    mail: "mailto:klk.kishore5@gmail.com",
    linkedin: "https://www.linkedin.com/in/kishorek31/",
    github: null,
    year: 2021,
  },
  {
    name: "Shoumik Dey",
    nickname: "Shoumik Dey",
    designation: "Senior S&A Member (March 2020- May 2021)",
    image: "/Alumni/50.jpeg",
    facebook: "http://facebook.com/shoumik.shonai",
    mail: "mailto:shoumikdey123@gmail.com",
    linkedin: "http://linkedin.com/in/shoumik-dey-53b69b16a",
    github: "https://github.com/shoumikdey",
    year: 2021,
  },
  {
    name: "Sai Manish",
    nickname: "Sai Manish",
    designation: "Senior Mechanical Member (March 2020- May 2021)",
    image: "/Alumni/19.png",
    facebook: "https://www.facebook.com/sai.manish.1270",
    mail: "mailto:saimanish12@gmail.com",
    linkedin: "https://www.linkedin.com/in/sai-manish-107368169",
    github: null,
    year: 2021,
  },
  {
    name: "Asish Boggavarapu",
    nickname: "Asish Boggavarapu",
    designation: "Senior Mechanical Member (March 2020- May 2021)",
    image: "/Alumni/21.png",
    facebook: "https://www.facebook.com/asish.boggavarapu",
    mail: "mailto:asishb212@gmail.com",
    linkedin: "https://www.linkedin.com/in/asish-boggavarapu-09bba2166",
    github: null,
    year: 2021,
  },
  {
    name: "Yatharth Agarwal",
    nickname: "Yatharth Agarwal",
    designation: "Technical Head (May 2021- June 2022)",
    image: "/Alumni/yatharth.jpg",
    facebook: null,
    mail: "mailto:yatharthrpi@gmail.com",
    linkedin: "https://www.linkedin.com/in/yatharth-agarwal-5123911a5",
    github: null,
    year: 2022,
  },
  {
    name: "Kallol Saha",
    nickname: "Failed_Mesh",
    designation: "Team Manager (May 2021- June 2022)",
    image: "/Alumni/kallol.jpg",
    facebook: null,
    mail: "mailto:kallolsaha1752001@gmail.com",
    linkedin: "https://www.linkedin.com/in/kallol-saha-45b5a41a3/",
    github: "https://github.com/FailedMesh",
    year: 2022,
  },
  {
    name: "Anshumaan Singh",
    nickname: "Anshu-man567",
    designation: "AI Head (May 2021- June 2022)",
    image: "/Alumni/anshumaan.jpg",
    facebook: null,
    mail: "mailto:anshumaan567@gmail.com",
    linkedin: "https://www.linkedin.com/in/anshumaan-singh-ab1ba91b3/",
    github: "https://github.com/Anshu-man567",
    year: 2022,
  },
  {
    name: "Raghav Thakar",
    nickname: "Tunak",
    designation: "Sensing & Automation Head (May 2021- June 2022)",
    image: "/Alumni/raghav.jpg",
    facebook: null,
    mail: "mailto:raghavthakar12@gmail.com",
    linkedin: "https://www.linkedin.com/in/raghavthakar/",
    github: "https://github.com/raghavthakar",
    year: 2022,
  },
  {
    name: "Ashwin Disa",
    nickname: "AshD",
    designation: "Mechanical Head (May 2021- June 2022)",
    image: "/Alumni/ashwin.jpg",
    facebook: "https://m.facebook.com/ashwin.disa",
    mail: "mailto:ashwin.disa@gmail.com",
    linkedin: "https://in.linkedin.com/in/ashwin-disa-725827180",
    github: null,
    year: 2022,
  },
  {
    name: "Naman Joshi",
    nickname: "NimWilliams",
    designation: "Management Head (May 2021- June 2022)",
    image: "/Alumni/naman.jpg",
    facebook: null,
    mail: "mailto:joshinaman14@gmail.com",
    linkedin: "https://www.linkedin.com/in/naman-joshi-476a07195/",
    github: "https://github.com/JoshiNaman",
    year: 2022,
  },
  {
    name: "Aryan Roy",
    nickname: "Le_King",
    designation: "AI Perception Head (May 2021- June 2022)",
    image: "/Alumni/aryan.jpg",
    facebook: null,
    mail: "mailto:aryanroy5678@gmail.com",
    linkedin: "https://www.linkedin.com/in/aryan-roy-914458189",
    github: null,
    year: 2022,
  },
  {
    name: "Shivansh Bakshi",
    nickname: "Worpe",
    designation: "AI Planning Head (May 2021- June 2022)",
    image: "/Alumni/shivansh.jpg",
    facebook: "https://www.facebook.com/shivansh.baks",
    mail: "mailto:shivansh.bakshi@gmail.com",
    linkedin: "https://www.linkedin.com/in/shivansh-bakshi/",
    github: "https://github.com/Shivansh-Bakshi",
    year: 2022,
  },
  {
    name: "Aditya Nirmale",
    nickname: "Crazzy",
    designation: "Sensing Head (May 2021- June 2022)",
    image: "/Alumni/aditya.jpg",
    facebook: null,
    mail: "mailto:adityanirmaleofficial@gmail.com",
    linkedin: "https://www.linkedin.com/in/aditya-nirmale/",
    github: null,
    year: 2022,
  },
  {
    name: "Akshat Pandey",
    nickname: "0wL",
    designation: "Automation Head (May 2021- June 2022)",
    image: "/Alumni/akshat.jpg",
    facebook: "https://www.facebook.com/akshat.pandey.35175633",
    mail: "mailto:akshat.darkmatter@gmail.com",
    linkedin: "https://www.linkedin.com/in/akshat-pandey-011b811a2/",
    github: "https://github.com/akshatowl",
    year: 2022,
  },
  {
    name: "Twinkle Goyal",
    nickname: "Deadlysalmon99",
    designation: "Mechanics Head (May 2021- June 2022)",
    image: "/Alumni/twinkle.jpg",
    facebook: null,
    mail: "mailto:manigoyal08@gmail.com",
    linkedin: "https://www.linkedin.com/in/twinkle-goyal-1236ba154/",
    github: null,
    year: 2022,
  },
  {
    name: "Srujan Kulkarni",
    nickname: "Srujan Kulkarni",
    designation: "Design Head (May 2021- June 2022)",
    image: "/Alumni/srujan.jpg",
    facebook: "https://www.facebook.com/srujan.kulkarni.7/",
    mail: "mailto:kulkarnisrujan77@gmail.com",
    linkedin: "http://www.linkedin.com/in/srujan-kulkarni-3378351b2",
    github: null,
    year: 2022,
  },
  {
    name: "Sridurga Krithivasan",
    nickname: "Durgzzz",
    designation: "PR and Publicity Head (May 2021- June 2022)",
    image: "/Alumni/sridurga.jpg",
    facebook: "https://www.facebook.com/sridurga.krithivasan",
    mail: "mailto:sridurga.krithi@gmail.com",
    linkedin: "http://linkedin.com/in/sridurga-krithivasan-b49075190",
    github: null,
    year: 2022,
  },
  {
    name: "Sathvik N K",
    nickname: "S@_o7",
    designation: "Admin and Finance Head (May 2021- June 2022)",
    image: "/Alumni/sathvik.jpg",
    facebook: "https://www.facebook.com/sathvik.nk.1",
    mail: "mailto:sathviknk14@gmail.com",
    linkedin: "https://www.linkedin.com/in/sathvik-nk-4956311a4",
    github: null,
    year: 2022,
  },
  {
    name: "Atula Tejaswi",
    nickname: "atutej",
    designation: "AI Member (May 2021- June 2022)",
    image: "/Alumni/atula.png",
    facebook: null,
    mail: "mailto:atulatejaswi@gmail.com",
    linkedin: "https://www.linkedin.com/in/atula-tejaswi-neerkaje-4b989b157",
    github: "https://github.com/atutej",
    year: 2022,
  },
  {
    name: "Ishika Jaiswal",
    nickname: "cinnamonroll",
    designation: "AI Member (May 2021- June 2022)",
    image: "/Alumni/ishika.png",
    facebook: null,
    mail: "mailto:jaiswalishikacrystal@gmail.com",
    linkedin: "https://www.linkedin.com/in/ishika-jaiswal-720b10193",
    github: "https://github.com/Ishikajaiswal",
    year: 2022,
  },
  {
    name: "Kaustav Sarkar",
    nickname: "Nightfury",
    designation: "AI Member (May 2021- June 2022)",
    image: "/Alumni/kaustav.jpg",
    facebook: null,
    mail: "mailto:kaustavsarkar2001@gmail.com",
    linkedin: "https://www.linkedin.com/in/kaustavsarkar/",
    github: "https://github.com/Kaustav-Sarkar",
    year: 2022,
  },
  {
    name: "Shrey Bihani",
    nickname: "Shrey Bihani",
    designation: "AI Member (May 2021- June 2022)",
    image: "/Alumni/shrey.jpg",
    facebook: null,
    mail: "mailto:shreybihani13@gmail.com",
    linkedin: null,
    github: null,
    year: 2022,
  },
  {
    name: "Shruti Jain",
    nickname: "Shruti Jain",
    designation: "AI Member (May 2021- June 2022)",
    image: "/Alumni/shruti.jpg",
    facebook: null,
    mail: "mailto:shrutipraveenjain@gmail.com",
    linkedin: "https://www.linkedin.com/in/shruti-jain-81a7581b4/",
    github: null,
    year: 2022,
  },
  {
    name: "Tanaya Gupte",
    nickname: "Sin(aya)/Cos(aya)",
    designation: "Mechanical Member (May 2021- June 2022)",
    image: "/Alumni/tanaya.jpg",
    facebook: null,
    mail: "mailto:tanaya1707@gmail.com",
    linkedin: "https://www.linkedin.com/in/tanaya-gupte-4313931a5/",
    github: "https://github.com/tanaya1707",
    year: 2022,
  },
  {
    name: "Nilabha Das",
    nickname: "N3rdys",
    designation: "Technical Head (August 2022 - August 2023)",
    image: "/Alumni/nilabha_new.jpg",
    facebook: null,
    mail: "mailto:nilabha.das@learner.manipal.edu",
    linkedin: "https://www.linkedin.com/in/nilabhadas314",
    github: "https://github.com/N3dys",
    year: 2023
  },
  {
    name: "Deep Gupta",
    nickname: "DeeplyDumb",
    designation: "Team Manager (August 2022 - August 2023)",
    image: "/Alumni/deep_new.jpg",
    facebook: null,
    mail: "mailto:deepgupta2508@gmail.com",
    linkedin: "https://www.linkedin.com/in/deep-gupta-35b927204/",
    github: "https://github.com/deep-25",
    year: 2023
  },
  {
    name: "Aditya Balaji",
    nickname: "Aditya Balaji ",
    designation: "Research & Development Head (August 2022 - August 2023)",
    image: "/Alumni/balaji.jpg",
    facebook: null,
    mail: "mailto:adityabalaji3@gmail.com",
    linkedin: null,
    github: null,
    year: 2023
  },
  {
    name: "Aaron Jomy",
    nickname: "Aaron Jomy",
    designation: "AI & Robotics Head (August 2022 - August 2023)",
    image: "/Alumni/aaron.jpg",
    facebook: null,
    mail: "mailto:aaronjomyjoseph@gmail.com",
    linkedin: "https://www.linkedin.com/in/aaron-jomy-2312a1201",
    github: "https://github.com/maximusron",
    year: 2023
  },
  {
    name: "Tushar Upadhyay",
    nickname: "Invok3",
    designation: "Sensing & Automation Head (August 2022 - August 2023)",
    image: "/Alumni/tushar_new.jpg",
    facebook: null,
    mail: "mailto:tushar.u110@gmail.com",
    linkedin: "https://www.linkedin.com/in/tushar-upadhyay-wiz/",
    github: "https://github.com/tushar-wiz",
    year: 2023
  },
  {
    name: "Divyam Shah",
    nickname: "Big Daddy D",
    designation: "Mechanical Head (August 2022 - August 2023)",
    image: "/Alumni/divyam.jpg",
    facebook: null,
    mail: "mailto:yamdiv.02@gmail.com",
    linkedin: "https://www.linkedin.com/in/divyam-shah-61bb8a219",
    github: null,
    year: 2023
  },
  {
    name: "Amogh Rao",
    nickname: "kowalski",
    designation: "Management Head (August 2022 - August 2023)",
    image: "/Alumni/amogh.jpg",
    facebook: null,
    mail: "mailto:amogh1515@gmail.com",
    linkedin: "https://www.linkedin.com/in/amogh-vivekananda-rao-62721b19b",
    github: null,
    year: 2023
  },
  {
    name: "Sai Anish Malla",
    nickname: "Spec_he",
    designation: "Senior AI Member (August 2022 - August 2023)",
    image: "/Alumni/anish.jpg",
    facebook: null,
    mail: "mailto:mallasaianish@gmail.com",
    linkedin: null,
    github: null,
    year: 2023
  },
  {
    name: "Ayush Kumar",
    nickname: "Alcazar",
    designation: "Senior AI Member (August 2022 - August 2023)",
    image: "/Alumni/ayush_new.jpg",
    facebook: null,
    mail: "mailto:ayush_7102@yahoo.in",
    linkedin: "https://www.linkedin.com/in/ayushk7102/",
    github: "https://github.com/ayushk7102",
    year: 2023
  },
  {
    name: "Neetigya Poddar",
    nickname: "N3rdys",
    designation: "Senior AI Member (August 2022 - August 2023)",
    image: "/Alumni/neetigya_new.jpg",
    facebook: null,
    mail: "mailto:neetigyapoddar1@gmail.com",
    linkedin: "https://www.linkedin.com/in/neetigya-poddar-a022811b6/",
    github: "https://github.com/NeetigyaPod",
    year: 2023
  },
  {
    name: "Aditya Choubey",
    nickname: "Dulo Peepo",
    designation: "Senior Sensing & Automation Member (August 2022 - August 2023)",
    image: "/Alumni/choubey_new.jpg",
    facebook: null,
    mail: "mailto:adityachoubey2512@gmail.com",
    linkedin: "https://www.linkedin.com/in/aditya-choubey-20bb9a1b3",
    github: "https://github.com/aditya-c2512",
    year: 2023
  },
  {
    name: "Darshan K S",
    nickname: "HomeZ",
    designation: "Senior Sensing & Automation Member (August 2022 - August 2023)",
    image: "/Alumni/darshan_new.jpg",
    facebook: null,
    mail: "mailto:darshanks.work@gmail.com",
    linkedin: "https://www.linkedin.com/in/darshan-k-s/",
    github: "https://github.com/darshan-k-s",
    year: 2023
  },
  {
    name: "Y Vishal Rao",
    nickname: "Mongoose",
    designation: "Senior Sensing & Automation Member (August 2022 - August 2023)",
    image: "/Alumni/vishal_new.jpg",
    facebook: null,
    mail: "mailto:yellurvishalrao@gmail.com",
    linkedin: null,
    github: null,
    year: 2023
  },
  {
    name: "Shreyas Bhat",
    nickname: "＼(ﾟ ｰﾟ＼)",
    designation: "Senior Mechanical Member (August 2022 - August 2023)",
    image: "/Alumni/shreyas.jpg",
    facebook: null,
    mail: "mailto:shreyasbhat17@gmail.com",
    linkedin: null,
    github: "https://github.com/Shreyas-gits",
    year: 2023
  },
];

export default alums;
