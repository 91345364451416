import React,{useState} from "react";
import Masonry, {ResponsiveMasonry} from "react-responsive-masonry";
import close from "../../assets/Recruitment/cross.png";
import countapi from 'countapi-js';
import { RightOutlined, LeftOutlined } from "@ant-design/icons"; 

const images = [
   
    "https://i.imgur.com/wZseq2M.jpeg",
    "https://i.imgur.com/pzgLFfn.jpeg",
    "https://i.imgur.com/bil0SE4.jpeg",
    "https://i.imgur.com/4pyXn1X.jpeg",
    "https://i.imgur.com/Z9CmczE.jpeg",
    "https://i.imgur.com/XgH1mcx.jpeg",
    "https://i.imgur.com/NuK93uf.jpeg",
    "https://i.imgur.com/RkUol9Z.jpeg",
    "https://i.imgur.com/ElEx5Oi.jpeg",
    "https://i.imgur.com/79raK1i.jpeg",
    "https://i.imgur.com/kyF5Xr3.jpeg",
    "https://i.imgur.com/f88oOS0.jpeg",
    "https://i.imgur.com/IyIZrBm.jpeg",
    "https://i.imgur.com/huRAbMx.jpeg",
    "https://i.imgur.com/NJ0R2ZU.jpeg",

    "https://i.imgur.com/6Qq4LfK.jpeg",
    "https://i.imgur.com/7P3h0RK.jpeg",
    "https://i.imgur.com/gvFeY34.jpeg",
    "https://i.imgur.com/dU3wTWa.jpeg",
    "https://i.imgur.com/WyMG2K5.jpeg",
    "https://i.imgur.com/NqbOmAq.jpeg",
    "https://i.imgur.com/0QyxVOH.jpeg",
    "https://i.imgur.com/iRimf0E.jpeg",
    "https://i.imgur.com/JTDIskG.jpeg",
    
]

const Gallery = () => {
    const [data, setData] = useState({img: '', i: 0})

    const viewImage = (img, i) =>{
        setData({img, i})
    } 

    const imgAction = (action) =>{
        let i = data.i;
        if(action === 'next-img'){
            setData({img: images[i + 1], i: i + 1});
        }
        if(action === 'previous-img'){
            setData({img: images[i - 1], i: i - 1});
        }
        if(!action){
            setData({img: '', i: 0});
        }
    }
    return (
        <>
            {data.img &&
                <div style={{
                    width: '100%',
                    height: '100vh',
                    background: 'black', 
                    position: 'fixed',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    overflow: 'hidden',
                    zIndex: 100,
                    
                }}>
                    <button onClick={() => imgAction()} style={{position: 'absolute', top: '80px', right: '20px'}}><img src={close} alt="close" style={{height: '25px', width: '25px'}} /></button>
                    <button onClick={() => imgAction('previous-img')} style={{ marginRight: '0.5rem' }}> <LeftOutlined /> </button>
                    <img src={data.img} style={{width: 'auto', maxWidth: '90%', maxHeight: '90%' }} alt=""/>
                    <button onClick={() => imgAction('next-img')} style={{ marginLeft: '0.5rem' }}> <RightOutlined /> </button>
                </div>    
            }
            <div style={{padding: '100px'}}>
                <ResponsiveMasonry
                        columnsCountBreakPoints={{350: 1, 750: 2, 900: 3}}
                    >
                    <Masonry gutter="20px">
                        {images.map((image, i) => (
                            <img
                                key={i}
                                src={image}
                                style={{width: "100%", display: "block", cursor: 'pointer', background: 'black',}}
                                alt=""
                                onClick={() =>viewImage(image, i)}
                            />
                        ))}
                    </Masonry>
                </ResponsiveMasonry>
            </div>
        </>
    );
};

countapi.visits('global').then((result) => {
    console.log(result.value);
});

export default Gallery