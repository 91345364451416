const mentors = [
  {
    name: "Dr. Ashalatha Nayak",
    designation: "Faculty Advisor",
    image: "https://i.imgur.com/lbP8Epd.jpg",
    facebook: null,
    mail: "mailto:asha.nayak@manipal.edu",
    linkedin: null,
    github: null,
  },
  {
    name: "Dr Andrew J",
    designation: "Faculty Advisor",
    image: "https://www.manipal.edu/content/dam/manipal/mu/mit/images/profile/Andrew_CSE.jpeg.transform/manipal-edu-transform-width-height-528px/image.jpg",
    facebook: null,
    mail: "mailto:andrew.j@manipal.edu",
    linkedin: null,
    github: null,
  },
  {
    name: "Mr. Mukund Kumar Menon",
    designation: "Faculty Advisor",
    image: "https://i.imgur.com/KkkkE0a.jpg",
    facebook: null,
    mail: "mailto:mukund.menon@manipal.edu",
    linkedin: null,
    github: null,
  },

  {
    name: "Mr. Shreyansh Daftry",
    designation: "External Mentor",
    image: "https://i.imgur.com/ppbmSZc.jpg",
    facebook: null,
    mail: "mailto:mukund.menon@manipal.edu",
    linkedin: null,
    github: null,
  },
];

export default mentors;
