import bossard from "../Sponsors/bossard.png";
import ct from "../Sponsors/composites-tomorrow.png";
import continental from "../Sponsors/continental.png";
import coolermaster from "../Sponsors/cooler-master.png";
import gigabyte from "../Sponsors/gigabyte.png";
import indianbank from "../Sponsors/indian-bank.png";
import mahindra from "../Sponsors/mahindra-rise.png";
import microsoft from "../Sponsors/microsoft.png";
import nationali from "../Sponsors/national-instruments.png";
import nvidia from "../Sponsors/nvidia.png";
import ouster from "../Sponsors/ouster.png";
import pepperlfuchs from "../Sponsors/pepperl-fuchs-text.png";
import sica from "../Sponsors/sica.png";
import sick from "../Sponsors/sick.png";
import simscale from "../Sponsors/simscale.png";
import tmotor from "../Sponsors/t-motors.png";
import vector from "../Sponsors/vector.png";
import veekay from "../Sponsors/veekay-impex.png";
import ximea from "../Sponsors/ximea.png";
import cathaypacific from "../Sponsors/cathay.png";
import maxon from "../Sponsors/maxon.png";
import wd from "../Sponsors/wd.png";
import tattu from "../Sponsors/tattu.png";

const sponsors = [
  {
    image: continental,
    link: "https://www.continental.com/en/",
  },
  {
    image: mahindra,
    link: "https://www.mahindra.com/rise",
  },
  {
    image: sick,
    link: "https://www.sick.com/in/en/",
  },
  {
    image: nvidia,
    link: "https://www.nvidia.com/en-us/",
  },
  {
    image: nationali,
    link: "https://www.ni.com/en-in.html",
  },
  {
    image: ximea,
    link: "https://www.ximea.com/",
  },
  {
    image: microsoft,
    link: "https://www.microsoft.com/en-in",
  },
  {
    image: coolermaster,
    link: "https://www.coolermaster.com/",
  },
  {
    image: vector,
    link: "https://www.vector.com/in/en/",
  },
  {
    image: gigabyte,
    link: "https://www.gigabyte.com/",
  },
  {
    image: pepperlfuchs,
    link: "https://www.pepperl-fuchs.com/india/hi/index.htm",
  },
  {
    image: indianbank,
    link: "https://indianbank.in/",
  },
  {
    image: sica,
    link: "http://www.sica.in/",
  },
  {
    image: bossard,
    link: "https://www.bossard.com/in-en/",
  },
  {
    image: veekay,
    link: "https://www.veekayimpex.com/",
  },
  {
    image: simscale,
    link: "https://www.simscale.com/",
  },
  {
    image: ouster,
    link: "https://ouster.com/",
  },
  {
    image: tmotor,
    link: "https://uav-en.tmotor.com/",
  },
  {
    image: ct,
    link: "https://compositestomorrow.com/",
  },
  {
    image: cathaypacific,
    link: "https://www.cathaypacific.com/cx/en_IN.html",
  },
  {
    image: maxon,
    link: "https://www.maxongroup.com/en",
  },
  {
    image: wd,
    link: "https://www.westerndigital.com/en-in",
  },
  {
    image: tattu,
    link: "https://genstattu.com/",
  },
];

export default sponsors;
