import React from "react";

export default function Errorpage() {
  return (
    <div className="errorpage-container">
      <div className="error">
        <h1>ERROR 404</h1>
        <h1>ERROR 404</h1>
        <h1>ERROR 404</h1>
        <h1>ERROR 404</h1>
        <h1>ERROR 404</h1>
        <button className="Button">Go Back</button>
      </div>
    </div>
  );
}
